@charset "UTF-8";
/*-----------------------------------------------------------------------------------------------------
    [Master Stylesheet]
		by : DIG
    Version : 1.0

    Projet : Decathlon avis
    Website :

-------------------------------------------------------------------------------------------------------*/
/* ICON Font */
@font-face {
  font-family: 'decaticons';

    src: url('../../fonts/decaticons.eot');
    src: url('../../fonts/decaticons.eot?#iefix') format('eot'), url('../../fonts/decaticons.woff') format('woff'), url('../../fonts/decaticons.ttf') format('truetype');
}

[class*='decaticon-']:before {
  font-family: 'decaticons';
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
    /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.decaticon-arrow-down:before,
.bootstrap-select > .dropdown-toggle .bs-caret:before,
.custom-select:after {
  content: '\E001';
}

.decaticon-arrow-left:before,
.st-btn.st-btn-left:after,
.form-content.form-review .form-top-button:before,
.popin .popin--close:after,
.mfp-wrap .mfp-container .mfp-arrow.mfp-arrow-left:before {
  content: '\E002';
}

.decaticon-arrow-right:before,
.st-btn.st-btn-right:after,
.mfp-wrap .mfp-container .mfp-arrow.mfp-arrow-right:before {
  content: '\E003';
}

.decaticon-arrow-up:before {
  content: '\E004';
}

.decaticon-close-2:before {
  content: '\E005';
}

.decaticon-close:before,
.popin .popin--close:before {
  content: '\E006';
}

.decaticon-dot:before {
  content: '\E007';
}

.decaticon-good:before {
  content: '\E008';
}

.decaticon-help:before {
  content: '\E009';
}

.decaticon-not-good:before {
  content: '\E00A';
}

.decaticon-quality-check-alt:before {
  content: '\E00B';
}

.decaticon-quality-check:before {
  content: '\E00C';
}

.decaticon-star-empty:before {
  content: '\E00D';
}

.decaticon-star:before {
  content: '\E00E';
}

/*-----------------------------------------------------------------------------------------------------
    [variables]

    + Neat
    + Breackpoint
    + Typo
-------------------------------------------------------------------------------------------------------*/
/* Neat Overrides */
/* Breackpoint */
/* Typo */
/* UI */
/*-----------------------------------------------------------------------------------------------------
    [mixins]
-------------------------------------------------------------------------------------------------------*/
/* Breakpoints
* DOC : https://gist.github.com/timknight/03e6335b8816aa534cf7
* Exemple : https://medium.com/developing-with-sass/creating-a-dead-simple-sass-mixin-to-handle-responsive-breakpoints-889927b37740
*/
/*-----------------------------------------------------------------------------------------------------
    [settings]
-------------------------------------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------------------------
    [Reset]

    http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
-------------------------------------------------------------------------------------------------------*/
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-size: 100%;
  margin: 0;
  padding: 0;
  border: 0;
    /*font: inherit;
  vertical-align: baseline;*/
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
    /*list-style: none;*/
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

/* quick print reset */
@media print {
  p,
    blockquote {
    orphans: 2;
    widows: 2;
  }

  blockquote,
    ul,
    ol {
    page-break-inside: avoid;
  }

  h1,
    h2,
    h3,
    caption {
    page-break-after: avoid;
  }

  * {
    color: black !important;
    background: transparent !important;
    text-shadow: none !important;
    -webkit-filter: none !important;
            filter: none !important;
    -ms-filter: none !important;
  }
    /* Black prints faster: h5bp.com/s */
  a,
    a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  .ir a:after,
    a[href^='javascript:']:after,
    a[href^='#']:after {
    content: '';
  }
    /* Don't show links for images, or javascript/internal links */
    pre,
    blockquote
    {
        border: 1px solid #999;

        page-break-inside: avoid;
    }
    thead
    {
        display: table-header-group;
    }
    /* h5bp.com/t */
    tr,
    img
    {
        page-break-inside: avoid;
    }
    img
    {
        max-width: 100% !important;
    }
    @page
    {
        margin: .5cm;
    }
    p,
    h2,
    h3
    {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3
    {
        page-break-after: avoid;
    }
}

/* orientation iOS font-size fix */
@media (orientation: landscape) and (max-device-width: 767px)
{
    html,
    body
    {
        -webkit-text-size-adjust: 100%;
    }
}

/*-----------------------------------------------------------------------------------------------------
    [colors]
-------------------------------------------------------------------------------------------------------*/
/* Application colors */
/* Typography colors */
/* Components colors */
/* Shades of grey */
/* Alerts colors */
/* Status colors */
/* Social colors */
/*-----------------------------------------------------------------------------------------------------
    [typography]
-------------------------------------------------------------------------------------------------------*/
body
{
    font-family: 'Roboto Condensed', Helvetica, 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    line-height: 140%;

    color: #242323;
}
@media (max-width: 767px)
{
    body
    {
        font-size: 16px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6
{
    font-family: 'Roboto Condensed', Helvetica, 'Helvetica Neue', Arial, sans-serif;
    line-height: 100%;

    margin: 0;

    color: #0082c3;
}

h1,
.h1-like
{
    font-size: 22px;
}

h2,
.h2-like
{
    font-size: 18px;
}

h3,
.h3-like
{
    font-size: 16px;
}

h4,
.h4-like
{
    font-size: 14px;
}

h5,
.h5-like
{
    font-size: 14px;
}

h6,
.h6-like
{
    font-size: 14px;
}

.success
{
    color: #02c18d;
}

.text-md
{
    font-size: 16px;
}

/*-----------------------------------------------------------------------------------------------------
    [helpers]

-------------------------------------------------------------------------------------------------------*/
.clear
{
    clear: both;
}

.clearfix:after
{
    display: block;
    visibility: hidden;
    clear: both;

    height: 0;

    content: ' ';
}

@media only screen and (max-width: 767px)
{
    .desktop-only
    {
        display: none !important;
    }
}

.mobile-only
{
    display: none !important;
}
@media only screen and (max-width: 767px)
{
    .mobile-only
    {
        display: block !important;
    }
}

.textcentered
{
    text-align: center;
}

/*-----------------------------------------------------------------------------------------------------
    [Grid]

    A grid with NEAT
    DOC : http://thoughtbot.github.io/neat-docs/latest/

-------------------------------------------------------------------------------------------------------*/
.container
{
    max-width: 940px;
    margin: 0 auto;
    padding: 0 10px;
}

@media only screen and (max-width: 767px)
{
    .grid_1
    {
        float: left;

        width: calc(8.33333% - 21.66667px);
        margin-left: 20px;
    }
    .grid_2
    {
        float: left;

        width: calc(16.66667% - 23.33333px);
        margin-left: 20px;
    }
    .grid_3
    {
        float: left;

        width: calc(25% - 25px);
        margin-left: 20px;
    }
    .grid_4
    {
        float: left;

        width: calc(33.33333% - 26.66667px);
        margin-left: 20px;
    }
    .grid_5
    {
        float: left;

        width: calc(41.66667% - 28.33333px);
        margin-left: 20px;
    }
    .grid_6
    {
        float: left;

        width: calc(50% - 30px);
        margin-left: 20px;
    }
    .grid_7
    {
        float: left;

        width: calc(58.33333% - 31.66667px);
        margin-left: 20px;
    }
    .grid_8
    {
        float: left;

        width: calc(66.66667% - 33.33333px);
        margin-left: 20px;
    }
    .grid_9
    {
        float: left;

        width: calc(75% - 35px);
        margin-left: 20px;
    }
    .grid_10
    {
        float: left;

        width: calc(83.33333% - 36.66667px);
        margin-left: 20px;
    }
    .grid_11
    {
        float: left;

        width: calc(91.66667% - 38.33333px);
        margin-left: 20px;
    }
    .grid_12
    {
        float: left;

        width: calc(100% - 40px);
        margin-left: 20px;
    }
}

/*-----------------------------------------------------------------------------------------------------
    [commons]
-------------------------------------------------------------------------------------------------------*/
html > body > img:only-child
{
    image-rendering: -moz-crisp-edges;
    image-rendering:      crisp-edges;
}

*
{
    box-sizing: border-box;
}

/*::-webkit-selection { background : $selection; }
::-moz-selection { background : $selection; }
::selection { background : $selection; }*/
html.noscroll,
body.noscroll
{
    position: fixed;

    overflow: hidden;

    width: 100%;
    height: 100%;
}

html.fixfixed,
body.fixfixed
{
    position: absolute !important;
}

body
{
    background: #f4f3f3;
}
body.bg-white
{
    background: #fff;
}
body.body-bg
{
    background-attachment: fixed;
    background-position: top center;
    background-size: cover;
}
@media (max-width: 767px)
{
    body.account.body-bg
    {
        background: #fff;
        background-image: none !important;
    }
}

a
{
    text-decoration: none;

    color: #242323;
}
a:hover
{
    text-decoration: none;

    color: #0082c3;
}

ul,
ol
{
    margin: 0;
}
ul li,
ol li
{
    margin: 0;

    list-style: none;
}

img
{
    display: block;

    max-width: 100%;
    height: auto;
}
@media only screen and (max-width: 767px)
{
    img
    {
        width: 100%;
        max-width: 100%;
    }
}

/*blockquote {
  color: $text-color;
  font-style: italic;
  font-family: $georgia;
  font-size: 18px;
  line-height: 170%;
  p {
    &:before {
      content: " “ ";
      color: $grey-light;
    }
    &:after {
      content: " ” ";
      color: $grey-light;
    }
  }
}*/
hr
{
    height: 1px;

    border: none;
    background: #f0f0f0;
}

table
{
    border-spacing: 0;
    border-collapse: collapse;
}
table th
{
    padding: .2em;

    text-align: left;

    border: 1px solid gray;
}
table td
{
    padding: .2em;

    border: 1px solid gray;
}

.txtleft
{
    text-align: left;
}

.txtright
{
    text-align: right;
}

.txtcenter
{
    text-align: center;
}
.txtcenter img
{
    display: inline-block;
}

.hidden
{
    visibility: hidden;
}

/*-----------------------------------------------------------------------------------------------------
    [block-animation]
-------------------------------------------------------------------------------------------------------*/
.block-animation
{
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    padding: 20px;

    text-align: center;
}
.block-animation .block-animation--list li
{
    display: none;
}
.block-animation .block-animation--list li p
{
    font-family: 'Roboto Condensed', Helvetica, 'Helvetica Neue', Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    font-style: italic;

    text-align: center;

    color: #727272;
}
@media (max-width: 767px)
{
    .block-animation .block-animation--list li p
    {
        cursor: pointer;
    }
}
.block-animation .block-animation--list li.current-step
{
    display: block;
}
.block-animation [class*='icon-']
{
    display: none;

    cursor: pointer;

    color: #727272;
}
@media (max-width: 767px)
{
    .block-animation [class*='icon-']
    {
        display: inline-block;
    }
}

/*-----------------------------------------------------------------------------------------------------
    [block-notification]
-------------------------------------------------------------------------------------------------------*/
.block-notification
{
    position: fixed;
    z-index: 999;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 10px 40px 10px 20px;

    transition: 200ms;
    -webkit-transform: translateY(120%);
            transform: translateY(120%);

    background: #fff;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, .1);
}
@media (max-width: 767px)
{
    .block-notification
    {
        display: block;
    }
}
.block-notification.active
{
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
.block-notification .block-notification--close
{
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;

    width: 40px;
    height: 40px;

    border: none;
    background: none;
}
.block-notification .block-notification--close [class*='decaticon-']
{
    font-size: 20px;

    color: #757575;
}
.block-notification .block-notification--close:hover
{
    cursor: pointer;
}
.block-notification .block-notification--close:hover [class*='decaticon-']
{
    color: #0082c3;
}
.block-notification .block-animation
{
    position: relative;

    padding: 0;
}
.block-notification .block-animation .block-animation--list li.current-step
{
    display: flex;

    justify-content: center;
    align-items: center;
}
.block-notification .block-animation .block-animation--list li img
{
    max-width: 110px;
    margin-right: 10px;
}
.block-notification .block-animation .block-animation--list li p
{
    margin-left: 10px;
}

/*-----------------------------------------------------------------------------------------------------
    [block-stars]
-------------------------------------------------------------------------------------------------------*/
.block-rating .block-rating--stars
{
    padding: 0;

    list-style-type: none;

    -webkit-user-select: none;
       -moz-user-select: none;
}
.block-rating .block-rating--stars .block-rating--star
{
    float: left;

    min-height: 60px;
    padding: 0 5px;
}
@media (max-width: 767px)
{
    .block-rating .block-rating--stars .block-rating--star
    {
        min-height: 0;
    }
}
.block-rating .block-rating--stars .block-rating--star label
{
    position: relative;

    display: block;

    text-align: center;
}
@media (max-width: 370px)
{
    .block-rating .block-rating--stars .block-rating--star
    {
        padding: 0 3px;
    }
}
.block-rating .block-rating--stars .block-rating--star span
{
    font-size: 10px;
    line-height: 100%;

    position: absolute;
    top: 40px;
    left: 50%;

    display: none;

    width: 70px;
    margin-left: -35px;

    text-align: center;

    color: #757575;
}
@media (max-width: 450px)
{
    .block-rating .block-rating--stars .block-rating--star span
    {
        top: 40px;

        width: 50px;
        margin-left: -25px;
    }
}
.block-rating .block-rating--stars .block-rating--star .block-rating--input
{
    display: none;
}
.block-rating .block-rating--stars .block-rating--star [class*='decaticon-']
{
    font-size: 40px;

    cursor: pointer;

    color: #8cc7e4;
}
@media (max-width: 370px)
{
    .block-rating .block-rating--stars .block-rating--star [class*='decaticon-']
    {
        font-size: 35px;
    }
}
@media (max-width: 340px)
{
    .block-rating .block-rating--stars .block-rating--star [class*='decaticon-']
    {
        font-size: 32px;
    }
}
.block-rating .block-rating--stars .block-rating--star:hover span
{
    display: block;
}
.block-rating .block-rating--stars .block-rating--star.hover
{
    /*span {
			  	display: block;
			  }*/
}
.block-rating .block-rating--stars .block-rating--star.hover [class*='decaticon-']
{
    color: #feb500;
}
.block-rating .block-rating--stars .block-rating--star.selected [class*='decaticon-']
{
    color: #feb500;
}
.block-rating .block-rating--stars .block-rating--star.selected.last span
{
    display: block;
}

@media (max-width: 767px)
{
    .block-rating.rated
    {
        padding-bottom: 15px;
    }
}

/*-----------------------------------------------------------------------------------------------------
    [block-review]
-------------------------------------------------------------------------------------------------------*/
.block-review
{
    position: relative;

    min-height: 170px;
    padding: 0 30px;
    padding-left: 190px;

    border-bottom: 1px solid #e6e6e6;
}
@media (max-width: 767px)
{
    .block-review
    {
        min-height: 0;
        padding-right: 10px;
        padding-bottom: 10px;
        padding-left: 100px;
    }
}
.block-review .block-review--img
{
    position: absolute;
    top: 20px;
    left: 30px;
}
@media (max-width: 767px)
{
    .block-review .block-review--img
    {
        top: 25px;
        left: 10px;
    }
}
.block-review .block-review--infos
{
    padding-top: 48px;
}
@media (max-width: 767px)
{
    .block-review .block-review--infos
    {
        padding-top: 20px;
    }
}
.block-review .block-review--infos .block-tag
{
    margin-bottom: 10px;
}
.block-review .block-review--infos .block-review--title
{
    font-size: 14px;
    font-weight: 400;

    margin-bottom: 30px;

    color: #242323;
}
@media (max-width: 767px)
{
    .block-review .block-review--infos .block-review--title
    {
        font-size: 16px;
    }
}
.block-review .block-review--infos .block-review--actions
{
    padding-left: 40px;
}
@media (max-width: 767px)
{
    .block-review .block-review--infos .block-review--actions
    {
        padding-left: 20px;
    }
}
.block-review .block-review--form
{
    max-width: 540px;
}
@media (max-width: 767px)
{
    .block-review .block-review--form
    {
        max-width: none;
    }
}
.block-review .block-review--form .expand-content
{
    display: none;

    margin-top: 10px;
    padding-top: 30px;

    border-top: 1px solid #e6e6e6;
}
@media (max-width: 767px)
{
    .block-review .block-review--form .expand-content
    {
        margin-left: -90px;
    }
}
.block-review .block-review--form .expand-content .form-group.submit
{
    padding: 20px 0 40px 0;
}
@media (max-width: 767px)
{
    .block-review .block-review--form .expand-content .form-group.submit .st-btn
    {
        display: block;

        width: 100%;
    }
}
.block-review .block-review--form .expand-content .form-group.submit .st-btn-default
{
    margin-top: 20px;
}
.block-review.reviewed
{
    background: #f4f4f4;
}
.block-review.reviewed .block-review--img img
{
    opacity: .7;

    mix-blend-mode: multiply;
}
.block-review.reviewed .block-review--infos
{
    padding-top: 75px;
}
@media (max-width: 767px)
{
    .block-review.reviewed .block-review--infos
    {
        padding-top: 40px;
        padding-bottom: 20px;
    }
}
.block-review.reviewed .block-review--infos .block-reviewed--check
{
    font-size: 20px;
    font-weight: bold;
    font-style: italic;

    position: relative;

    margin-bottom: 10px;
    padding-left: 40px;

    color: #0082c3;
}
@media (max-width: 767px)
{
    .block-review.reviewed .block-review--infos .block-reviewed--check
    {
        font-size: 18px;

        padding-left: 40px;
    }
}
.block-review.reviewed .block-review--infos .block-reviewed--check [class*='decaticon-']
{
    font-size: 25px;

    position: absolute;
    top: 0;
    left: 0;
}
@media (max-width: 767px)
{
    .block-review.reviewed .block-review--infos .block-reviewed--check [class*='decaticon-']
    {
        font-size: 25px;
    }
}
.block-review.reviewed .block-review--infos .block-reviewed--text
{
    font-size: 14px;

    padding-left: 40px;

    color: #757575;
}
@media (max-width: 767px)
{
    .block-review.reviewed .block-review--infos .block-reviewed--text
    {
        line-height: 140%;

        padding-left: 45px;
    }
}
.block-review.product-review .block-review--img
{
    max-width: 120px;
}
@media (max-width: 767px)
{
    .block-review.product-review .block-review--img
    {
        max-width: 70px;
    }
}
.block-review.product-review.with-tag .block-review--infos
{
    padding-top: 30px;
}
@media (max-width: 767px)
{
    .block-review.product-review.with-tag .block-review--infos
    {
        padding-top: 20px;
    }
}
@media (max-width: 767px)
{
    .block-review.product-review.with-tag .block-review--img
    {
        top: 65px;
    }
}
.block-review.store-review
{
    min-height: 140px;
}
.block-review.store-review .block-review--img
{
    top: 35px;
    left: 60px;
}
@media (max-width: 767px)
{
    .block-review.store-review .block-review--img
    {
        top: 20px;
        left: 20px;
    }
}
.block-review.store-review .block-review--img .svg-icon
{
    max-width: 60px;
}
.block-review.store-review .block-review--infos
{
    padding-top: 35px;
}
@media (max-width: 767px)
{
    .block-review.store-review .block-review--infos
    {
        padding-top: 20px;
    }
}
.block-review.store-review .block-review--infos .block-review--title
{
    font-size: 16px;
    font-weight: 700;

    margin-bottom: 25px;

    text-transform: uppercase;

    color: #242323;
}
@media (max-width: 767px)
{
    .block-review.store-review.reviewed
    {
        min-height: 0;
    }
}
.block-review.store-review.reviewed .block-review--infos
{
    padding-top: 60px;
}
@media (max-width: 767px)
{
    .block-review.store-review.reviewed .block-review--infos
    {
        padding-top: 40px;
        padding-bottom: 20px;
    }
}
.block-review.product-reviewed
{
    position: relative;

    min-height: 0;
    padding-right: 200px;
    padding-bottom: 20px;

    background: #fff;
}
@media (max-width: 767px)
{
    .block-review.product-reviewed
    {
        padding: 20px;

        text-align: center;
    }
}
.block-review.product-reviewed .block-review--close
{
    position: absolute;
    top: 0;
    right: 0;

    width: 40px;
    height: 40px;

    border: none;
    background: none;
}
.block-review.product-reviewed .block-review--close [class*='decaticon-']
{
    font-size: 20px;

    color: #0082c3;
}
.block-review.product-reviewed .block-review--close:hover [class*='decaticon-']
{
    color: #757575;
}
.block-review.product-reviewed .block-review--button
{
    position: absolute;
    top: 50%;
    right: 80px;

    margin-top: -10px;

    text-decoration: underline;

    color: #0082c3;
    border: none;
    background: none;
}
@media (max-width: 767px)
{
    .block-review.product-reviewed .block-review--button
    {
        position: relative;
        top: auto;
        right: auto;

        display: inline-block;
    }
}
.block-review.product-reviewed .block-review--button [class*='decaticon-']
{
    font-size: 9px;

    display: inline-block;

    text-decoration: none;
}
.block-review.product-reviewed .block-review--img
{
    top: 15px;
    left: 70px;

    max-width: 60px;
}
@media (max-width: 767px)
{
    .block-review.product-reviewed .block-review--img
    {
        position: relative;
        top: auto;
        left: auto;

        display: inline-block;
        float: left;

        width: 10%;
        margin-left: 20%;
    }
}
@media (max-width: 540px)
{
    .block-review.product-reviewed .block-review--img
    {
        width: 20%;
        margin-left: 10%;
    }
}
@media (max-width: 450px)
{
    .block-review.product-reviewed .block-review--img
    {
        width: 25%;
        margin-left: 5%;
    }
}
.block-review.product-reviewed .block-review--infos
{
    padding-top: 25px;
}
@media (max-width: 767px)
{
    .block-review.product-reviewed .block-review--infos
    {
        float: left;

        width: 50%;
        margin-right: 20%;
        margin-bottom: 20px;
        padding-top: 10px;
        padding-left: 10px;

        text-align: left;
    }
}
@media (max-width: 540px)
{
    .block-review.product-reviewed .block-review--infos
    {
        width: 60%;
        margin-right: 10%;
    }
}
@media (max-width: 450px)
{
    .block-review.product-reviewed .block-review--infos
    {
        width: 65%;
        margin-right: 5%;
    }
}
.block-review.product-reviewed .block-review--infos .block-review--title
{
    margin-bottom: 10px;
}
.block-review.product-reviewed .block-review--text
{
    font-size: 16px;
    font-weight: bold;

    text-transform: uppercase;
}
@media (max-width: 767px)
{
    .block-review.product-reviewed .block-review--text
    {
        clear: both;
    }
}
.block-review.product-reviewed .block-review--form
{
    display: none;

    margin-top: 20px;
    padding-top: 20px;

    border-top: 1px solid #e6e6e6;
}
.block-review.product-reviewed .block-review--form .block-rating
{
    padding-top: 30px;
}
.block-review.product-reviewed .block-review--form .block-review--form-title
{
    font-size: 16px;
}
.block-review.product-reviewed .block-review--form .expand-content
{
    display: block;

    margin: 0;
    padding: 0;

    border: none;
}

/*-----------------------------------------------------------------------------------------------------
    [block-step]
-------------------------------------------------------------------------------------------------------*/
.block-step
{
    position: relative;

    margin-bottom: 40px;

    background: #fff;
}
@media only screen and (max-width: 1400px)
{
    .block-step
    {
        /*padding-left: 10px;
		padding-right: 10px;*/
    }
}
.block-step .container
{
    position: relative;
}
.block-step .progress-bar
{
    position: relative;

    width: 90%;
    height: 2px;

    background: #dbdbdb;
}
@media (max-width: 767px)
{
    .block-step .progress-bar
    {
        width: 80%;
    }
}
.block-step .progress-bar span
{
    position: absolute;
    top: 0;
    left: 0;

    height: 2px;

    transition: width .2s;

    background: #0082c3;
}
.block-step .step-count
{
    font-size: 16px;

    position: absolute;
    z-index: 1;
    top: -10px;
    right: 0;

    color: #0082c3;
}
.block-step .step-count u
{
    text-decoration: none;
}
@media (max-width: 1400px)
{
    .block-step .step-count
    {
        font-size: 15px;

        right: 10px;
    }
}

/*-----------------------------------------------------------------------------------------------------
    [block-tag]
-------------------------------------------------------------------------------------------------------*/
.block-tag
{
    position: relative;

    display: inline-block;

    padding: 5px 10px;

    background: #ffea28;
}
.block-tag .block-tag--content
{
    font-weight: bold;

    text-transform: uppercase;
}
@media (max-width: 767px)
{
    .block-tag .block-tag--content
    {
        font-size: 14px;
    }
}
.block-tag .tooltip
{
    position: absolute;
    top: 0;
    right: -40px;
}

/*-----------------------------------------------------------------------------------------------------
    [breadcrumb]
-------------------------------------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------------------------
    [Buttons]
-------------------------------------------------------------------------------------------------------*/
button
{
    cursor: pointer;
}

.st-btn
{
    font-family: 'Roboto Condensed', Helvetica, 'Helvetica Neue', Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;

    position: relative;
    z-index: 0;

    display: inline-block;
    overflow: hidden;

    min-width: 200px;
    margin-bottom: 10px;
    padding: 10px 35px;

    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    color: #fff;
}
@media (max-width: 767px)
{
    .st-btn
    {
        padding-top: 13px;
        padding-bottom: 13px;
    }
}
@media (max-width: 450px)
{
    .st-btn
    {
        display: block;
    }
}
.st-btn.icon-button
{
    font-weight: bold;
    font-style: italic;

    text-transform: uppercase;
}
.st-btn.icon-button [class*='decaticon-']
{
    font-size: 20px;

    position: relative;
    top: 4px;
}
@media (max-width: 767px)
{
    .st-btn.st-btn-primary,
    .st-btn.st-btn-default,
    .st-btn.st-btn-yellow
    {
        min-height: 50px;
    }
}
.st-btn.st-btn-primary:before,
.st-btn.st-btn-default:before,
.st-btn.st-btn-yellow:before
{
    position: absolute;
    top: 0;

    width: 36px;
    height: 100%;

    content: '';

    opacity: .2;
    background: #fff;
}
.st-btn.st-btn-primary:after,
.st-btn.st-btn-default:after,
.st-btn.st-btn-yellow:after
{
    font-family: 'decaticons';
    font-size: 10px;

    top: 50%;

    -webkit-transform: translateY(-50%);

            transform: translateY(-50%);
}
@media (max-width: 767px)
{
    .st-btn.st-btn-primary.st-btn-small,
    .st-btn.st-btn-default.st-btn-small,
    .st-btn.st-btn-yellow.st-btn-small
    {
        min-height: 0;
    }
}
.st-btn.st-btn-primary
{
    border: none;
    background:         linear-gradient(#4eb0ed 0, #0173b9 100%);
    background-color: #0173b9;
    box-shadow: rgba(0, 0, 0, .168627) 0 1px 2px 0;
}
.st-btn.st-btn-primary:hover
{
    color: #fff;
    box-shadow: rgba(0, 0, 0, .270588) 0 1px 3px 0;
}
.st-btn.st-btn-primary:active
{
    color: #fff;
}
.st-btn.st-btn-primary:active:not(.disabled)
{
    background: #0173b9;
    box-shadow: none;
}
.st-btn.st-btn-default
{
    color: #393939;
    border: 1px solid #dcdbdb;
    background:         linear-gradient(#fafafa 0, #e2e2e2 100%);
    background-color: #fafafa;
    box-shadow: rgba(0, 0, 0, .0470588) 0 1px 1px 0;
}
.st-btn.st-btn-default:hover
{
    border-color: #e6e6e6;
    box-shadow: rgba(0, 0, 0, .0980392) 0 1px 3px 0;
}
.st-btn.st-btn-default:active
{
    color: #393939;
}
.st-btn.st-btn-default:active:not(.disabled)
{
    border-color: #e2e2e2;
    background: #e2e2e2;
    box-shadow: none;
}
.st-btn.st-btn-yellow
{
    color: #393939;
    border: none;
    background: linear-gradient(#fef63a 0, #eec423 100%);
    background-color: #fef63a;
    box-shadow: rgba(0, 0, 0, .0901961) 0 1px 2px 0;
}
.st-btn.st-btn-yellow:hover
{
    box-shadow: rgba(0, 0, 0, .117647) 0 1px 2px 0;
}
.st-btn.st-btn-yellow:active
{
    color: #393939;
}
.st-btn.st-btn-yellow:active:not(.disabled)
{
    background: #eec423;
    box-shadow: none;
}
.st-btn.st-btn-small
{
    font-size: 14px;

    min-width: 150px;
    padding: 6px 25px 6px 25px;
}
@media (max-width: 767px)
{
    .st-btn.st-btn-small
    {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
.st-btn.st-btn-small:before
{
    width: 26px;
}
@media (max-width: 767px)
{
    .st-btn.st-btn-small:before
    {
        width: 36px;
    }
}
.st-btn.st-btn-left:after,
.st-btn.st-btn-right:after
{
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    font-stretch: normal;
    line-height: 1;

    position: absolute;

    display: inline-block;

    text-rendering: auto;
}
.st-btn.st-btn-left
{
    padding-left: 51px;
}
.st-btn.st-btn-left:before
{
    left: 0;

    -webkit-transform: skew(10deg);

            transform: skew(10deg);
    -webkit-transform-origin: 0 100% 0;
            transform-origin: 0 100% 0;
}
.st-btn.st-btn-left:after
{
    left: 12px;
}
.st-btn.st-btn-left.st-btn-small
{
    padding-right: 15px;
    padding-left: 35px;
}
.st-btn.st-btn-left.st-btn-small:after
{
    left: 9px;
}
@media (max-width: 767px)
{
    .st-btn.st-btn-left.st-btn-small:after
    {
        left: 14px;
    }
}
.st-btn.st-btn-right
{
    padding-right: 51px;
}
.st-btn.st-btn-right:before
{
    right: 0;

    -webkit-transform: skew(-10deg);

            transform: skew(-10deg);
    -webkit-transform-origin: 100% 100% 0;
            transform-origin: 100% 100% 0;
}
.st-btn.st-btn-right:after
{
    right: 12px;
}
.st-btn.st-btn-right.st-btn-small
{
    padding-right: 35px;
    padding-left: 15px;
}
.st-btn.st-btn-right.st-btn-small:after
{
    right: 9px;
}
@media (max-width: 767px)
{
    .st-btn.st-btn-right.st-btn-small:after
    {
        right: 14px;
    }
}
.st-btn.disabled
{
    cursor: not-allowed;

    opacity: .5;
    box-shadow: none !important;
}
.st-btn:active,
.st-btn:hover
{
    text-decoration: none;

    outline: 0;
}
.st-btn:active:not(.disabled):before
{
    display: none;
}
.st-btn:hover:not(.disabled):before
{
    width: 100% !important;

    -webkit-transform: none !important;
            transform: none !important;
}

.btn
{
    font-family: 'Roboto Condensed', Helvetica, 'Helvetica Neue', Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;

    position: relative;
    z-index: 0;

    display: inline-block;
    overflow: hidden;

    min-width: 230px;
    margin-bottom: 10px;
    padding: 11px 35px 10px 35px;

    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    color: #000;
    border-radius: 4px;

    align-items: center;
    justify-content: center;
}
.btn .decaticon-arrow-right
{
    font-size: 12px;

    position: absolute;
    top: 51%;
    right: 15px;

    -webkit-transform: translateY(-50%);

            transform: translateY(-50%);
}
.btn:after
{
    position: absolute;
    z-index: -1;
    top: 100%;
    left: -25%;

    width: 150%;
    height: 150%;

    content: '';
    transition: -webkit-transform .18s ease;
    transition: transform .18s ease;
    transition: transform .18s ease, -webkit-transform .18s ease;
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
    -webkit-transform-origin: calc(83.33333% - 2.85714em) 0;
            transform-origin: calc(83.33333% - 2.85714em) 0;

    background-color: #fdd835;
}
.btn:hover
{
    color: #000;
}
.btn:hover:after
{
    transition: -webkit-transform .36s ease;
    transition: transform .36s ease;
    transition: transform .36s ease, -webkit-transform .36s ease;
    -webkit-transform: rotate(0deg) translateY(-100%);
            transform: rotate(0deg) translateY(-100%);
}
.btn.yellow
{
    background: #ffea28;
}

/*-----------------------------------------------------------------------------------------------------
    [Forms review]
-------------------------------------------------------------------------------------------------------*/
.form-content.form-review
{
    position: relative;
}
.form-content.form-review .form-top-button
{
    line-height: inherit;

    position: absolute;
    z-index: 999;
    top: -12px;
    left: auto;
    left: -10px;

    width: 40px;
    height: 40px;
    margin-left: 0;
    padding: 0;

    cursor: pointer;
    transition: opacity .2s;
    text-align: center;

    opacity: 1;
    opacity: 1;
    color: #242323;
    border: none;
    background: none;
}
.form-content.form-review .form-top-button:before
{
    font-family: decaticons;
    font-size: 14px;

    position: relative;
    top: 1px;

    pointer-events: none;

    color: #242323;
}
.form-content.form-review .form-top-button:hover
{
    opacity: .6;
}
.form-content.form-review .inline-field
{
    min-height: 90px;
    padding: 15px 0 5px 0;

    border-bottom: 1px solid #dbdbdb;
}
@media (max-width: 980px)
{
    .form-content.form-review .inline-field
    {
        min-height: 0;
        padding-top: 5px;

        text-align: center;
    }
}
.form-content.form-review .inline-field .label
{
    font-size: 16px;

    display: block;
    float: left;

    width: 230px;
    padding: 22px 20px 0 0;

    text-align: left;
}
@media (max-width: 980px)
{
    .form-content.form-review .inline-field .label
    {
        font-size: 17px;

        float: none;

        width: auto;
        padding: 10px 10px 10px 10px;

        text-align: center;
    }
}
.form-content.form-review .inline-field .label.double-line
{
    padding-top: 5px;
}
.form-content.form-review .inline-field .icon-field
{
    display: none;
    float: left;

    width: 60px;
    min-height: 75px;
}
@media (max-width: 767px)
{
    .form-content.form-review .inline-field .icon-field
    {
        min-width: 0;

        vertical-align: top;
    }
}
@media (max-width: 450px)
{
    .form-content.form-review .inline-field .icon-field
    {
        width: 52px;
    }
}
.form-content.form-review .inline-field .icon-field label
{
    position: relative;

    display: block;

    cursor: pointer;
    text-align: center;
}
@media (max-width: 767px)
{
    .form-content.form-review .inline-field .icon-field label
    {
        font-size: 11px;
    }
}
.form-content.form-review .inline-field .icon-field label span
{
    position: absolute;
    top: 55px;
    left: 50%;

    display: none;

    width: 80px;
    margin-left: -40px;
}
.form-content.form-review .inline-field .icon-field .icon img
{
    width: 55px;
    max-width: 100%;
    height: auto;
    height: auto;
    margin: 0 auto 10px auto;
}
.form-content.form-review .inline-field .icon-field .icon img.icon-off,
.form-content.form-review .inline-field .icon-field .icon img.icon-animated
{
    display: none;
}
.form-content.form-review .inline-field .icon-field .icon img:hover img
{
    display: none;
}
.form-content.form-review .inline-field .icon-field .icon img:hover.icon-on
{
    display: block;
}
.form-content.form-review .inline-field .icon-field.icon-disabled .icon img
{
    display: none;
}
.form-content.form-review .inline-field .icon-field.icon-disabled .icon img.icon-off
{
    display: block;
}
.form-content.form-review .inline-field .icon-field.icon-selected label
{
    font-weight: bold;

    color: #0082c3;
}
.form-content.form-review .inline-field .icon-field.icon-selected .icon img
{
    display: none;
}
.form-content.form-review .inline-field .icon-field.icon-selected .icon img.icon-animated
{
    display: block;
}
.form-content.form-review .inline-field .icon-field.icon-selected .icon:hover img
{
    display: none;
}
.form-content.form-review .inline-field .icon-field.icon-selected .icon:hover img.icon-animated
{
    display: block;
}
.form-content.form-review .inline-field.has-selection .icon-field.icon-selected label span
{
    display: block;
}
.form-content.form-review .inline-field.active .icon-field
{
    display: block;
}
@media (max-width: 980px)
{
    .form-content.form-review .inline-field.active .icon-field
    {
        display: inline-block;
        float: none;
    }
}
.form-content.form-review .inline-field.active .label
{
    color: #242323;
}
.form-content.form-review .inline-field.current .icon-field
{
    display: block;
}
@media (max-width: 980px)
{
    .form-content.form-review .inline-field.current .icon-field
    {
        display: inline-block;
        float: none;
    }
}
.form-content.form-review .inline-field.primary-step
{
    margin-bottom: 30px;
}
.form-content.form-review .inline-field.centered
{
    text-align: center;
}
.form-content.form-review .inline-field.centered .icon-field
{
    display: inline-block;
    float: none;
}
@media (max-width: 980px)
{
    .form-content.form-review .secondary-step .inline-field
    {
        min-height: 0;

        text-align: center;

        background: #f5f5f5;
    }
}
@media (max-width: 980px)
{
    .form-content.form-review .secondary-step .inline-field.active
    {
        background: none;
    }
}
.form-content.form-review .secondary-step.inactive
{
    position: relative;
    /*&:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.8);
          z-index: 9;
        }*/
}
.form-content.form-review .secondary-step.inactive .field-step
{
    opacity: .4;
}
.form-content.form-review .last-step
{
    padding-top: 20px;
}
@media (max-width: 767px)
{
    .form-content.form-review .last-step .st-btn
    {
        display: block;

        width: 100%;
    }
}
.form-content.form-review .last-step.inactive
{
    position: relative;
}
.form-content.form-review .last-step.inactive:before
{
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 80%;

    content: '';

    background: rgba(255, 255, 255, .8);
}
@media (max-width: 767px)
{
    .form-content.form-review .last-step.inactive:before
    {
        height: 70%;
    }
}

.form-content .submit
{
    padding-top: 20px;

    text-align: right;
}
@media (max-width: 767px)
{
    .form-content .submit .st-btn
    {
        width: 100%;
    }
}
.form-content .submit .st-btn-left
{
    float: left;
}
@media (min-width: 980px)
{
    .form-content .submit .st-btn-left
    {
        font-weight: normal;

        min-width: 0;
        min-height: 40px;
        padding: 10px 10px 10px 30px;

        color: #757575;
        border: none;
        background: none;
        box-shadow: none;
    }
    .form-content .submit .st-btn-left:hover
    {
        color: #0082c3;
    }
}
@media (max-width: 767px)
{
    .form-content .submit .st-btn-left
    {
        float: none;

        margin-top: 20px;
    }
}

/*-----------------------------------------------------------------------------------------------------
    [Forms-step]
-------------------------------------------------------------------------------------------------------*/
.form-step .form-step-container
{
    position: relative;

    display: none;
}
.form-step .form-step-container.first-step
{
    display: block;
}
.form-step .form-step-container .subtitle
{
    text-align: center;

    color: #757575;
}
.form-step .form-step-container .form-step-actions
{
    padding-top: 50px;
}
@media (max-width: 767px)
{
    .form-step .form-step-container .form-step-actions
    {
        padding-top: 20px;
    }
}
.form-step .form-step-container .form-step-actions .form-step-next
{
    float: right;
}
@media (max-width: 767px)
{
    .form-step .form-step-container .form-step-actions .form-step-next
    {
        float: none;

        width: 100%;
    }
}
.form-step .form-step-container .form-step-actions .form-step-jump
{
    font-size: 14px;

    float: right;

    min-height: 40px;
    margin-right: 10px;
    padding: 10px;

    text-transform: uppercase;

    color: #757575;
    border: none;
    background: none;
}
.form-step .form-step-container .form-step-actions .form-step-jump:hover
{
    color: #0082c3;
}
@media (max-width: 767px)
{
    .form-step .form-step-container .form-step-actions .form-step-jump
    {
        float: none;

        width: 100%;
        margin-right: 0;
    }
}
.form-step .form-step-container .form-step-actions .form-step-prev
{
    float: left;
    /*border: none;
				background: none;
				font-size: 14px;
				text-transform: uppercase;
				color: $text-color-alt;
				[class*="decaticon"] {
					font-size: 10px;
					position: relative;
					top: 1px;
				}*/
}
@media (max-width: 767px)
{
    .form-step .form-step-container .form-step-actions .form-step-prev
    {
        float: none;

        width: 100%;
        margin-top: 20px;
    }
}
@media (min-width: 980px)
{
    .form-step .form-step-container .form-step-actions .st-btn-default
    {
        font-weight: normal;

        min-width: 0;
        min-height: 40px;
        padding: 10px 10px 10px 30px;

        color: #757575;
        border: none;
        background: none;
        box-shadow: none;
    }
    .form-step .form-step-container .form-step-actions .st-btn-default:hover
    {
        color: #0082c3;
    }
}
@media (max-width: 767px)
{
    .form-step .form-step-container .form-step-actions .st-btn-default
    {
        float: none;

        width: 100%;
        margin-top: 20px;
    }
}

/*-----------------------------------------------------------------------------------------------------
    [forms-inscription]
-------------------------------------------------------------------------------------------------------*/
.form-inscription
{
    padding: 50px 50px 100px 50px;
}
@media (max-width: 767px)
{
    .form-inscription
    {
        padding: 30px 20px 50px 20px;
    }
}
.form-inscription .title.primary
{
    font-size: 26px;
    font-style: normal;

    margin-bottom: 35px;
}
.form-inscription .form-group .control-field
{
    float: left;

    width: 400px;
}
@media (max-width: 767px)
{
    .form-inscription .form-group .control-field
    {
        float: none;

        width: auto;
    }
}
.form-inscription .form-group .st-btn
{
    float: left;

    margin: 30px 0 0 30px;
}
@media (max-width: 767px)
{
    .form-inscription .form-group .st-btn
    {
        float: none;

        margin: 0;
    }
}
@media (max-width: 450px)
{
    .form-inscription .form-group .st-btn
    {
        width: 100%;
    }
}

/*-----------------------------------------------------------------------------------------------------
    [Forms]
-------------------------------------------------------------------------------------------------------*/
:focus
{
    outline: none;
}

::-moz-focus-inner
{
    border: 0;
    outline: none;
}

input[type='search']
{
    -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button
{
    -webkit-border-radius: 0;

    -webkit-appearance: none;
}

.search-form--input,
.control-input
{
    -webkit-border-radius: 0;

    -webkit-appearance: none;
}

button
{
    font-family: 'Roboto Condensed', Helvetica, 'Helvetica Neue', Arial, sans-serif;

    padding: 0;

    cursor: pointer;

    outline: none;

    -webkit-appearance: none;
}
button:active,
button:focus
{
    outline: none;
}

.form-content .form-group.form-group-hidden
{
    display: none;
}

.form-content .form-group.form-group-expand
{
    margin-bottom: 40px;
}

.form-content .form-group .control-label
{
    font-size: 16px;
    font-weight: 400;

    display: block;

    margin-bottom: 10px;
}
@media (max-width: 767px)
{
    .form-content .form-group .control-label
    {
        font-size: 14px;
    }
}
.form-content .form-group .control-label span
{
    text-transform: none;
}
.form-content .form-group .control-label abbr
{
    cursor: inherit !important;
    text-decoration: none !important;

    color: #e80202;
    border-bottom: none !important;
}
.form-content .form-group .control-label.link-label a
{
    float: right;

    text-transform: none;
}
@media (max-width: 767px)
{
    .form-content .form-group .control-label.link-label a
    {
        display: none;
    }
}

.form-content .form-group .control-field
{
    display: block;

    margin-bottom: 20px;
}
.form-content .form-group .control-field ::-webkit-input-placeholder
{
    color: #b4b4b4;
}
.form-content .form-group .control-field :-moz-placeholder
{
    color: #b4b4b4;
}
.form-content .form-group .control-field ::-moz-placeholder
{
    color: #b4b4b4;
}
.form-content .form-group .control-field :-ms-input-placeholder
{
    color: #b4b4b4;
}
.form-content .form-group .control-field > *
{
    font-family: 'Roboto Condensed', Helvetica, 'Helvetica Neue', Arial, sans-serif;
    font-size: 15px;
}
@media (max-width: 767px)
{
    .form-content .form-group .control-field > *
    {
        font-size: 16px;
    }
}
.form-content .form-group .control-field .control-input
{
    width: 100%;
    height: 40px;
    padding: 0 15px;

    border: 1px solid #e6e6e6;
    border-radius: 0;

    -webkit-appearance: none;
}
.form-content .form-group .control-field .control-input:focus
{
    border-color: #0082c3;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
}
.form-content .form-group .control-field .control-textarea
{
    width: 100%;
    min-height: 120px;
    padding: 15px;

    border: 1px solid #e6e6e6;
}
.form-content .form-group .control-field .control-textarea:focus
{
    border-color: #0082c3;
}

.form-content .form-group .field-required
{
    font-size: 12px;

    display: block;

    margin-top: 10px;

    text-align: right;

    color: #757575;
}
@media (max-width: 767px)
{
    .form-content .form-group .field-required
    {
        text-align: left;
    }
}

.form-content .form-group.group-tooltip
{
    position: relative;

    padding-top: 30px;
}
.form-content .form-group.group-tooltip .tooltip
{
    position: absolute;
    top: 0;
    right: 0;
}
.form-content .form-group.group-tooltip .tooltip .block-tooltip
{
    right: 0;
}
.form-content .form-group.group-tooltip .tooltip .block-tooltip:after
{
    right: 10px;
}
.form-content .form-group.group-tooltip .tooltip .block-tooltip:before
{
    right: 8px;
}

.form-content .form-group.radio-button
{
    padding-bottom: 20px;
}
.form-content .form-group.radio-button .custom-radio-button
{
    margin-right: 10px;
}
@media (max-width: 767px)
{
    .form-content .form-group.radio-button .custom-radio-button
    {
        display: inline-block;

        width: 48%;
        margin-right: 2%;
    }
    .form-content .form-group.radio-button .custom-radio-button span
    {
        display: block;

        text-align: center;
    }
}
.form-content .form-group.radio-button .custom-radio-button + .custom-radio-button
{
    margin-right: 0;
}
@media (max-width: 767px)
{
    .form-content .form-group.radio-button .custom-radio-button + .custom-radio-button
    {
        margin-right: 0;
    }
}

.form-content .form-group.change-name
{
    padding: 20px 0 30px 0;

    border-top: 1px solid #e6e6e6;
}
.form-content .form-group.change-name .change-name--text
{
    font-size: 16px;
}
.form-content .form-group.change-name .change-name--button
{
    margin-left: 30px;
    padding: 0;

    text-decoration: underline;

    color: #0082c3;
    border: none;
    background: none;
}
.form-content .form-group.change-name .control-field
{
    display: none;

    padding-top: 10px;
}

.form-content .form-group.checkbox.cgv
{
    font-size: 12px;
}
@media (max-width: 767px)
{
    .form-content .form-group.checkbox.cgv
    {
        margin-bottom: 20px;
    }
}
.form-content .form-group.checkbox.cgv a
{
    text-decoration: underline;

    color: #0082c3;
}

.form-content .form-group.submit
{
    padding-top: 20px;

    text-align: right;
}

.form-group .bootstrap-select > .dropdown-toggle:focus
{
    outline: 0 !important;
    outline: 0 !important;
    outline-offset: 0;
}

.form-list li
{
    /*label {
			cursor: pointer;
			display: block;
			position: relative;
			padding: 15px 40px 15px 10px;
			border-bottom: 1px solid $border-color-light;
			font-size: 17px;
			[class*="decaticon-"] {
				position: absolute;
				right: 0;
				top: 50%;
				margin-top: -10px;
			}
			input[type="radio"] {
				display: none;
			}
			&:hover {
				color: $primary;
				[class*="decaticon-"] {}
			}
			input:checked ~ span,
			input:checked ~ [class*="decaticon-"] {
				color: $primary;
			}
		}*/
}
.form-list li .form-step-next
{
    font-size: 17px;

    position: relative;

    display: block;

    width: 100%;
    padding: 15px 40px 15px 10px;

    cursor: pointer;
    text-align: left;

    border: none;
    border-bottom: 1px solid #e6e6e6;
    background: none;
}
.form-list li .form-step-next [class*='decaticon-']
{
    position: absolute;
    top: 50%;
    right: 0;

    margin-top: -10px;
}
.form-list li .form-step-next:hover
{
    color: #0082c3;
}

.bootstrap-select
{
    position: relative;

    width: 100% !important;
}
.bootstrap-select > .dropdown-toggle
{
    font-family: 'Roboto Condensed', Helvetica, 'Helvetica Neue', Arial, sans-serif;

    position: relative;
    position: relative;
    z-index: 10;

    display: flex;
    overflow: hidden;

    height: 40px;
    padding: 6px 12px 6px 12px;

    color: #242323;
    border: 1px solid #e6e6e6;
    background: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .1);

    align-items: center;
}
@media (max-width: 767px)
{
    .bootstrap-select > .dropdown-toggle
    {
        font-size: 16px;
    }
}
.bootstrap-select > .dropdown-toggle:before
{
    position: absolute;
    top: 0;
    right: 0;

    width: 34px;
    height: 100%;

    content: '';
    transition: background-color .2s ease;
    -webkit-transform: skewX(-8deg);
            transform: skewX(-8deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;

    border-left: solid 1px #e6e6e6;
}
.bootstrap-select > .dropdown-toggle:hover:before
{
    background: #f4f4f4;
}
.bootstrap-select > .dropdown-toggle .filter-option
{
    font-size: 15px;

    display: inline-block;
    overflow: hidden;

    width: 100%;

    text-align: left;

    color: #242323;
}
.bootstrap-select > .dropdown-toggle .bs-caret:before
{
    font-family: 'decaticons';
    font-size: 14px;

    position: absolute;
    top: 50%;
    right: 8px;

    margin-top: -10px;

    vertical-align: middle;

    color: #242323;
}
.bootstrap-select > .dropdown-toggle .bs-caret .caret
{
    display: none;
}
.bootstrap-select .dropdown-menu
{
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 0;

    display: none;

    min-width: 100%;
    margin-top: -1px;

    border: 1px solid #e6e6e6;
    background: #fff;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .18);
}
.bootstrap-select .dropdown-menu ul li a
{
    display: block;

    padding: 6px 12px;

    white-space: nowrap;
}
@media (max-width: 767px)
{
    .bootstrap-select .dropdown-menu ul li a
    {
        font-size: 16px;
    }
}
.bootstrap-select .dropdown-menu ul li a:hover
{
    color: #fff;
    background: #0082c3;
}
.bootstrap-select .dropdown-menu ul li a:focus
{
    color: #242323;
    background: #f4f4f4;
}
.bootstrap-select.open > .dropdown-toggle:before
{
    background: #f4f4f4;
}
.bootstrap-select.open .dropdown-menu
{
    display: block;
}

.custom-radio-button input[type='radio']
{
    position: absolute;
    z-index: -1;

    opacity: 0;
}

.custom-radio-button span
{
    padding: 5px 20px;

    cursor: pointer;

    color: #0082c3;
    border: 1px solid #dcdbdb;
    border-radius: 2px;
    background:         linear-gradient(#fafafa 0, #e2e2e2 100%);
    background-color: #fafafa;
    box-shadow: rgba(0, 0, 0, .0470588) 0 1px 1px 0;
}
.custom-radio-button span [class*='decaticon-']
{
    position: relative;
    top: 2px;

    margin-right: 5px;
}

.custom-radio-button input:checked ~ span
{
    color: #fff;
    border: 1px solid #4eb0ed;
    background:         linear-gradient(#4eb0ed 0, #0173b9 100%);
    background-color: #0173b9;
}

/*
 * Checkboxes and radios
 */
.form-group.radio,
.form-group.checkbox
{
    margin: 10px 0;

    vertical-align: top;
}
.centered .form-group.radio,
.centered
  .form-group.checkbox
{
    display: block;
}

.control-inline .form-group.radio,
.control-inline .form-group.checkbox
{
    margin-right: 30px;
}
.control-inline .form-group.radio:last-child,
.control-inline .form-group.checkbox:last-child
{
    margin-right: 0;
}

.centered .control-inline .form-group.radio,
.centered .control-inline .form-group.checkbox
{
    display: block;

    max-width: 380px;
}

.control-indicator
{
    line-height: 10px;

    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 20px;
    height: 20px;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    text-align: center;
    pointer-events: none;

    color: #eee;
    border: 1px solid #0082c3;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 80% 80%;
}
.light .control-indicator
{
    border-color: #e6e6e6;
}
.small .control-indicator
{
    top: 2px;

    width: 15px;
    height: 15px;

    background-size: 80% 80%;
}

.control-radio .control-indicator
{
    border-radius: 99em;
}

.control-checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'],
.control-radio input[type='radio'],
.radio-inline input[type='radio']
{
    position: absolute;

    margin-top: 4px\9;
    margin-left: -20px;
}

.control-checkbox input,
.control-radio input
{
    position: absolute;
    z-index: -1;

    opacity: 0;
}

label.control-checkbox,
label.control-radio
{
    font-family: 'Roboto Condensed';
    font-size: 14px;
    font-weight: normal;

    position: relative;

    display: block;

    min-height: 20px;
    margin-bottom: 0;
    padding-left: 30px;

    cursor: pointer;

    color: #555;
}
@media (max-width: 767px)
{
    label.control-checkbox,
    label.control-radio
    {
        font-size: 16px;
    }
}
.small label.control-checkbox,
.small
  label.control-radio
{
    padding-left: 25px;
}

.control-radio input:checked ~ .control-indicator
{
    border-color: #0082c3;
}

.control-radio input:checked ~ .control-indicator:before
{
    position: absolute;
    top: 50%;
    left: 50%;

    width: 8px;
    height: 8px;

    content: '';
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    border-radius: 99em;
    background: #0082c3;
}

.control-checkbox input:checked ~ .control-indicator
{
    color: #fff;
    border-color: #0082c3;
    background-color: #0082c3;
}

.control-checkbox input:checked ~ .control-indicator
{
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
    background-size: 12px 12px;
}

.control-checkbox + .form-help,
.control-radio + .form-help
{
    font-family: 'Roboto Condensed', Helvetica, 'Helvetica Neue', Arial, sans-serif;
    font-size: 12px;

    position: relative;

    display: inline-block !important;

    margin-top: 4px;
    margin-top: 8px !important;
    padding: 8px 12px;

    color: #fff;
    background: #e60202;
}

.control-checkbox + .form-help .caret,
.control-radio + .form-help .caret
{
    position: absolute;
    top: auto;
    bottom: 100%;
    left: 4px !important;

    display: block;

    width: 0;
    height: 0;

    vertical-align: middle;

    color: #e60202;
    border-top: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    border-left: 4px solid transparent;
}

.control-stacked .form-group.radio,
.control-stacked .form-group.checkbox
{
    display: block;

    margin-left: 0;
}

.control-stacked .form-group.radio .control-checkbox,
.control-stacked .form-group.checkbox .control-checkbox,
.control-stacked .form-group.radio .control-radio,
.control-stacked .form-group.checkbox .control-radio
{
    display: block;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label
{
    color: #e60202;
}

.has-error .control-indicator
{
    border-color: #e60202;
}

/* Custom select pointer
/* DOC : Filament Group | Doc : https://github.com/filamentgroup/select-css/
-------------------------------------------------------------------------------------------------------*/
.custom-select
{
    position: relative;

    display: block;
    overflow: hidden;

    max-width: 470px;
    height: 45px;
    padding-right: 30px;

    border: 2px solid #e6e6e6;
    background: #fff;
}
.custom-select:before
{
    position: absolute;
    top: 0;
    right: 0;

    width: 34px;
    height: 100%;

    content: '';
    transition: background-color .2s ease;
    -webkit-transform: skewX(-8deg);
            transform: skewX(-8deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    pointer-events: none;

    border-left: solid 1px #e6e6e6;
}
.custom-select:after
{
    font-family: 'decaticons';
    font-size: 14px;

    position: absolute;
    top: 50%;
    right: 8px;

    margin-top: -13px;

    vertical-align: middle;
    pointer-events: none;

    color: #242323;
}
.custom-select select
{
    line-height: 140%;

    width: 130%;
    height: 100%;
    margin: 0;
    padding: 5px 8px;

    border: 1px solid transparent;
    border: none;
    outline: none;
    background: #fff;
    background-color: transparent;
    background-image: none;
    box-shadow: none;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.custom-select:hover:before
{
    background-color: #f4f4f4;
}
.custom-select select:focus
{
    outline: none;
}
.custom-select select option
{
    font-weight: normal;
}

/* START OF UGLY BROWSER-SPECIFIC HACKS */
x:-o-prefocus,
.custom-select::after
{
    display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    .custom-select select::-ms-expand
    {
        display: none;
    }
    .custom-select select:focus::-ms-value
    {
        color: #222;
        background: transparent;
    }
}

@-moz-document url-prefix()
{
    .custom-select
    {
        overflow: hidden;
    }
    .custom-select select
    {
        width: 120%;
        width:      calc(100% + 30px);
    }
}

.custom-select select:-moz-focusring
{
    color: transparent;
    text-shadow: 0 0 0 #000;
}

/*-----------------------------------------------------------------------------------------------------
    [overlay]
-------------------------------------------------------------------------------------------------------*/
.overlay
{
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    background: rgba(0, 0, 0, .8);
}

/*-----------------------------------------------------------------------------------------------------
    [pagination]
-------------------------------------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------------------------
    [popin-form]
-------------------------------------------------------------------------------------------------------*/
.popin.popin-workshop
{
    padding: 40px 130px;
}
@media (max-width: 980px)
{
    .popin.popin-workshop
    {
        padding: 20px 20px 40px 20px;
    }
}
.popin.popin-workshop .title
{
    font-size: 24px;

    margin-bottom: 20px;

    text-align: center;
    text-transform: none;
}
@media (max-width: 980px)
{
    .popin.popin-workshop .title
    {
        font-size: 18px;
    }
}
.popin.popin-workshop .title.main
{
    font-size: 16px;
    font-weight: bold;

    margin-bottom: 30px;

    text-transform: uppercase;

    color: #242323;
}
@media (max-width: 980px)
{
    .popin.popin-workshop .title.main
    {
        font-size: 14px;
    }
}
@media (max-width: 980px)
{
    .popin.popin-workshop .submit .st-btn-default
    {
        display: block;
    }
}

.popin.popin-shop
{
    padding: 40px 130px;
}
@media (max-width: 980px)
{
    .popin.popin-shop
    {
        padding: 20px 20px 40px 20px;
    }
}
.popin.popin-shop .title
{
    font-size: 24px;

    margin-bottom: 20px;

    text-align: center;
    text-transform: none;
}
@media (max-width: 980px)
{
    .popin.popin-shop .title
    {
        font-size: 18px;
    }
}
.popin.popin-shop .title.main
{
    font-size: 16px;
    font-weight: bold;

    margin-bottom: 30px;

    text-transform: uppercase;

    color: #242323;
}
@media (max-width: 980px)
{
    .popin.popin-shop .title.main
    {
        font-size: 14px;
    }
}

/*-----------------------------------------------------------------------------------------------------
    [popin]
-------------------------------------------------------------------------------------------------------*/
.popin
{
    position: relative;

    width: auto;
    max-width: 800px;
    margin: 50px auto 0 auto;
    padding: 20px;

    border: 1px solid #e6e6e6;
    background: #fff;

    -webkit-overflow-scrolling: touch;
}
@media (max-width: 800px)
{
    .popin
    {
        height: 100%;
        margin: 0;

        border: none;
    }
}
.popin .mfp-close
{
    display: none;
}
.popin .popin--close
{
    line-height: inherit;

    position: absolute;
    z-index: 999;
    top: -35px;
    left: 50%;

    width: 24px;
    height: 24px;
    margin-left: -12px;
    padding: 0;

    cursor: pointer;
    transition: opacity .2s;
    text-align: center;

    opacity: 1;
    color: #fff;
    border: 1px solid #fff;
    background: none;
}
.popin .popin--close:before
{
    font-family: decaticons;
    font-size: 8px;

    position: relative;
    top: -1px;

    pointer-events: none;

    color: #fff;
}
.popin .popin--close:hover
{
    opacity: .6;
}
.popin .popin--close:after
{
    font-family: decaticons;

    position: relative;

    display: none;

    pointer-events: none;
}
@media (max-width: 800px)
{
    .popin .popin--close
    {
        top: 5px;
        right: auto;
        left: auto;
        left: 5px;

        display: none;

        width: 40px;
        height: 40px;
        margin-left: 0;

        opacity: 1;
        color: #242323;
        border: none;
    }
    .popin .popin--close:before
    {
        font-size: 20px;

        top: 9px;

        display: none;

        color: #242323;
    }
    .popin .popin--close:after
    {
        display: block;
    }
}
.popin.no-padding
{
    padding: 0;
}
.popin .popin--head
{
    font-size: 16px;

    padding: 20px 30px;
}
@media (max-width: 800px)
{
    .popin .popin--head
    {
        padding: 10px 15px;
    }
}
.popin .popin--footer
{
    padding: 30px;
}
@media (max-width: 800px)
{
    .popin .popin--footer
    {
        padding: 15px 15px 100px 15px;
    }
}
.popin .popin--footer .popin--actions
{
    display: flex;
    flex-direction: row-reverse;

    justify-content: space-between;
    align-items: center;
}
@media (max-width: 800px)
{
    .popin .popin--footer .popin--actions
    {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;

        padding: 15px;

        -webkit-transform: translateZ(0);

                transform: translateZ(0);

        border-top: 1px solid #e6e6e6;
        background: #fff;
        box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .18);
    }
}
.popin .popin--footer .popin--actions.align-right
{
    flex-direction: row;

    justify-content: flex-end;
}
.popin .popin--footer .popin--actions.align-left
{
    flex-direction: row;

    justify-content: flex-start;
}
.popin .popin--footer .popin--actions .st-btn
{
    min-width: 200px;
    margin-bottom: 0;
}
@media (max-width: 767px)
{
    .popin .popin--footer .popin--actions .st-btn
    {
        min-width: 150px;
    }
}
@media (max-width: 450px)
{
    .popin .popin--footer .popin--actions .st-btn
    {
        font-size: 14px;

        min-width: 120px;
    }
}
.popin .popin--footer.with-link
{
    padding-top: 12px;
}
.popin .popin--footer.with-link p
{
    font-size: 12px;

    color: #757575;
}
.popin .popin--footer.with-link .popin--actions
{
    padding-top: 12px;
}

.mfp-wrap
{
    -webkit-transform: translateZ(0);

    -webkit-overflow-scrolling: touch;
    -webkit-backface-visibility: hidden;
    /*.fixfixed & {
    position: absolute !important;
  }*/
}
@media (max-width: 800px)
{
    .mfp-wrap .mfp-container
    {
        padding: 0;
    }
}
.mfp-wrap .mfp-container.mfp-image-holder .mfp-figure:after
{
    display: none;
}
.mfp-wrap .mfp-container.mfp-image-holder .mfp-close
{
    cursor: pointer;

    color: #fff;
}
@media (max-width: 767px)
{
    .mfp-wrap .mfp-container.mfp-image-holder .mfp-close
    {
        right: 5px;
    }
}
.mfp-wrap .mfp-container .mfp-arrow:after
{
    display: none;
}
.mfp-wrap .mfp-container .mfp-arrow:before
{
    font-family: 'decaticons';
    font-size: 40px;

    opacity: 1;
    color: #fff;
    border: none;
}

@media (max-width: 800px)
{
    .mfp-bg.mfp-ready
    {
        opacity: 1;
        background: #fff;
    }
}

.popin--actions
{
    display: none;
    flex-direction: row-reverse;

    justify-content: space-between;
    align-items: center;
}
.popin--actions.ui-footer-fixed
{
    z-index: 9999;

    display: flex;
}
@media (max-width: 800px)
{
    .popin--actions
    {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;

        padding: 15px;

        -webkit-transform: translateZ(0);

                transform: translateZ(0);

        border-top: 1px solid #e6e6e6;
        background: #fff;
        box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .18);
    }
}
.popin--actions.align-right
{
    flex-direction: row;

    justify-content: flex-end;
}
.popin--actions.align-left
{
    flex-direction: row;

    justify-content: flex-start;
}
.popin--actions .st-btn
{
    min-width: 200px;
    margin-bottom: 0;
}
@media (max-width: 767px)
{
    .popin--actions .st-btn
    {
        min-width: 150px;
    }
}
@media (max-width: 450px)
{
    .popin--actions .st-btn
    {
        font-size: 14px;

        min-width: 120px;
    }
}

/*-----------------------------------------------------------------------------------------------------
    [title]
-------------------------------------------------------------------------------------------------------*/
.title.centered
{
    text-align: center;
}

.title.primary
{
    font-family: 'Roboto Condensed', Helvetica, 'Helvetica Neue', Arial, sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;

    padding-bottom: 30px;

    text-transform: uppercase;

    color: #242323;
    background: url('../../images/portal/title-bg.svg') no-repeat center bottom;
    background-size: 160px auto;
}
.title.primary span
{
    color: #0082c3;
}
.title.primary small
{
    font-size: 15px;

    display: block;

    text-transform: none;

    color: #0082c3;
}

.title.secondary
{
    font-size: 22px;
    font-weight: 700;
    font-style: italic;

    margin-bottom: 30px;

    text-transform: uppercase;

    color: #242323;
}
.title.secondary span
{
    font-weight: 300;
}
.title.secondary a
{
    font-size: 14px;
    font-weight: 400;
    font-style: normal;

    display: block;

    text-align: center;
    text-decoration: underline;
    text-transform: none;

    color: #0082c3;
}

.title.page-title
{
    font-size: 46px;

    padding: 30px 10px;
}
@media (max-width: 767px)
{
    .title.page-title
    {
        font-size: 30px;
    }
}

.subtitle
{
    font-size: 20px;

    margin-top: 20px;

    text-align: center;
}

/*-----------------------------------------------------------------------------------------------------
    [tooltip]
-------------------------------------------------------------------------------------------------------*/
.tooltip
{
    position: relative;

    width: 40px;
    height: 30px;
}
.tooltip .tooltip-trigger
{
    cursor: pointer;
    text-align: center;
}
.tooltip .tooltip-trigger [class*='decaticon-']
{
    font-size: 20px;

    position: relative;
    top: 4px;

    color: #0082c3;
}
.tooltip .block-tooltip
{
    position: absolute;
    z-index: 999;
    top: 40px;
    right: -30px;

    display: none;

    width: 290px;
    padding: 20px 30px 20px 20px;

    background: #fafafa;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
}
@media (max-width: 767px)
{
    .tooltip .block-tooltip
    {
        right: 0;
    }
}
.tooltip .block-tooltip:before
{
    position: absolute;
    z-index: 9;
    top: 0;
    right: 40px;

    display: block;

    width: 30px;
    height: 5px;

    content: '';

    background: #fafafa;
}
.tooltip .block-tooltip:after
{
    position: absolute;
    top: -11px;
    right: 40px;

    display: block;

    width: 0;
    height: 0;

    content: '';

    border-width: 0 11px 11px 11px;
    border-style: solid;
    border-color: transparent transparent #fafafa transparent;

    -webkit-filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, .2));
            filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, .2));
}
@media (max-width: 767px)
{
    .tooltip .block-tooltip:after
    {
        right: 10px;
    }
}
.tooltip .block-tooltip .block-tooltip--close
{
    position: absolute;
    top: 0;
    right: 0;

    width: 30px;
    height: 30px;

    text-align: center;

    border: none;
    background: none;
}
.tooltip .block-tooltip .block-tooltip--close [class*='decaticon-']
{
    font-size: 10px;

    color: #c8c8c8;
}
.tooltip .block-tooltip .block-tooltip--close:hover [class*='decaticon-']
{
    color: #242323;
}

.popin .tooltip .block-tooltip {
    left: 0;
}

.popin .tooltip .block-tooltip:before {
    left: 10px;
}

.popin .tooltip .block-tooltip:after {
    left: 10px;
}

/*-----------------------------------------------------------------------------------------------------
    [header]
-------------------------------------------------------------------------------------------------------*/
.header
{
    position: relative;
    z-index: 2;

    background: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
}
@media (max-width: 767px)
{
    .header
    {
        text-align: center;
    }
}
.header .logo
{
    display: block;
    float: left;

    margin: 10px 0;
}
@media (max-width: 767px)
{
    .header .logo
    {
        display: inline-block;
        float: none;

        max-width: 150px;
    }
}
.header .nav-content
{
    float: right;
}
@media (max-width: 767px)
{
    .header .nav-content
    {
        float: none;
    }
}

/*-----------------------------------------------------------------------------------------------------
    [footer]
-------------------------------------------------------------------------------------------------------*/
.footer
{
    padding: 80px 0;

    text-align: center;
}
.footer .logo
{
    display: inline-block;

    max-width: 100px;
}
@media (max-width: 767px)
{
    .page-connexion .footer
    {
        background: #fff;
    }
}

/*-----------------------------------------------------------------------------------------------------
    [list-review]
-------------------------------------------------------------------------------------------------------*/
.list-date
{
    font-size: 16px;

    min-height: 25px;
    margin-top: 40px;
    margin-bottom: 5px;

    color: #666;
}
@media (max-width: 1400px)
{
    .list-date
    {
        margin-left: 15px;
    }
}

.list-review
{
    position: relative;
    z-index: 1;

    background: #fff;
}
.list-review.reviewed
{
    margin-top: 20px;
}

.list-actions a
{
    font-size: 16px;

    position: relative;
    z-index: 1;

    display: block;

    padding: 40px 20px;

    text-align: center;
    text-transform: uppercase;

    background: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
}

/*-----------------------------------------------------------------------------------------------------
    [list-service]
-------------------------------------------------------------------------------------------------------*/
.list-service
{
    margin: 0;
    margin-bottom: 20px;

    list-style: none;
}
.list-service li
{
    float: left;

    width: 29%;
    width: calc(33.3333% - 20px);
    margin: 0 10px 20px 10px;
}
@media only screen and (max-width: 450px)
{
    .list-service li
    {
        width: 42%;
        width: calc(50% - 20px);
    }
}
.list-service li:nth-child(10) ~ li
{
    display: none;
}
.list-service li label
{
    display: block;

    height: 140px;
    margin-bottom: 0;
    padding: 10px 10px 10px 10px;

    text-align: center;

    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .25);
}
@media (max-width: 767px)
{
    .list-service li label
    {
        height: 150px;
        padding: 10px 5px;
    }
}
.list-service li label [class*='icon']
{
    width: 100px;
    height: 80px;
}
.list-service li label .icon-active
{
    display: none;
}
.list-service li label img
{
    display: inline-block;

    max-width: 80px;
    margin-bottom: 10px;
}
.list-service li label span
{
    font-size: 16px;
    line-height: 120%;

    display: block;

    word-wrap: break-word;

    color: #000;
}
.list-service li label:hover
{
    cursor: pointer;

    box-shadow: 1px 1px 2px rgba(0, 0, 0, .45);
}
.list-service li label.active
{
    border-color: #cfcfcf;
    background: #0082c3;
    box-shadow: none;
}
.list-service li label.active span
{
    color: #fff;
}
.list-service li label.active .icon
{
    display: none;
}
.list-service li label.active .icon-active
{
    display: inline-block;
}
.list-service li .all-item
{
    font-size: 16px;

    display: block;

    width: 100%;
    height: 140px;
    padding-top: 50px;
    padding-bottom: 10px;

    text-align: center;
    text-decoration: none;

    border: 1px solid #ccc;
    border-radius: 4px;
    background: none;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .25);
}
@media (max-width: 767px)
{
    .list-service li .all-item
    {
        height: 150px;
        padding-top: 60px;
    }
}
.list-service li .all-item span
{
    display: block;
}
.list-service li .all-item [class*='icon-']
{
    margin-bottom: 5px;
}
.list-service li .all-item [class*='icon-']:before
{
    font-size: 50px;

    color: #0082c3;
}
.list-service li .all-item:hover
{
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .45);
}
.list-service li .all-item.active
{
    border-color: #fff;
    background: none;
    box-shadow: none;
}
.list-service li .all-item.active span
{
    color: #fff;
}
.list-service li .all-item.active [class*='icon-']:before
{
    color: #ccc;
}

/*-----------------------------------------------------------------------------------------------------
    [nav]
-------------------------------------------------------------------------------------------------------*/
@media (max-width: 767px)
{
    .nav-content .main-menu ul
    {
        float: none;

        text-align: center;
    }
}

.nav-content .main-menu ul li
{
    float: left;
}
@media (max-width: 767px)
{
    .nav-content .main-menu ul li
    {
        display: inline-block;
        float: none;
    }
}
.nav-content .main-menu ul li a
{
    position: relative;

    display: block;

    padding: 12px 10px 0 10px;

    text-align: center;
}
@media (max-width: 767px)
{
    .nav-content .main-menu ul li a
    {
        padding: 10px 10px;
    }
}
.nav-content .main-menu ul li a:after
{
    position: absolute;
    top: 0;
    left: 50%;

    width: 0;
    height: 2px;

    content: '';
    transition: all .2s;

    background: #242323;
}
.nav-content .main-menu ul li a .svg-icon
{
    display: inline-block;

    max-width: 20px;
}
@media (max-width: 767px)
{
    .nav-content .main-menu ul li a .svg-icon
    {
        display: none;
    }
}
.nav-content .main-menu ul li a span
{
    font-family: Roboto, Helvetica, 'Helvetica Neue', Arial, sans-serif;
    font-size: 11px;

    display: block;

    text-transform: uppercase;
}
.nav-content .main-menu ul li a:hover
{
    color: #242323;
}
.nav-content .main-menu ul li a:hover:after
{
    left: 0;

    width: 100%;
}

/*-----------------------------------------------------------------------------------------------------
    [page]
-------------------------------------------------------------------------------------------------------*/
@media (max-width: 767px)
{
    .main > .container
    {
        padding: 0;
    }
}

.main.main-sidebar > .container
{
    position: relative;
}

.main.main-sidebar .sidebar
{
    position: absolute;
    top: 30px;
    left: 0;

    width: 220px;
    height: 100%;
    height: calc(100% - 30px);

    background: #fff;
}
@media (max-width: 767px)
{
    .main.main-sidebar .sidebar
    {
        position: relative;
        top: auto;
        left: auto;

        width: auto;
        max-width: 240px;
        height: auto;
        margin: 40px auto;
    }
}

.main.main-sidebar .content
{
    margin-left: 240px;
}
@media (max-width: 767px)
{
    .main.main-sidebar .content
    {
        margin: 0;
    }
}

/* Page list */
/*-----------------------------------------------------------------------------------------------------
    [page-connexion]
-------------------------------------------------------------------------------------------------------*/
.page-connexion
{
    overflow-x: hidden;

    background: linear-gradient(to bottom, #f4f3f3 50%, #fff 50%);
}
@media (max-width: 767px)
{
    .page-connexion
    {
        background: linear-gradient(to bottom, #f4f3f3 30%, #fff 30%);
    }
}

.hp_avis--container
{
    max-width: 1280px;
    margin: 0 auto;
}

.hp_avis--title
{
    font-size: 32px;
    font-weight: 700;
    font-style: italic;

    position: relative;

    margin-bottom: 50px;

    text-align: center;
    text-transform: uppercase;

    color: #0082c3;
}
@media (max-width: 767px)
{
    .hp_avis--title
    {
        font-size: 24px;
    }
}
.hp_avis--title:after
{
    position: absolute;
    bottom: -30px;
    left: 50%;

    display: block;

    width: 120px;
    height: 20px;

    content: '';
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

    background: url('../../images/portal/title-pointer.svg') no-repeat;
    background-size: 120px auto;
}

.hp_avis--subtitle
{
    font-size: 20px;

    margin-bottom: 50px;

    text-align: center;

    color: #242323;
}
@media (max-width: 767px)
{
    .hp_avis--subtitle
    {
        font-size: 16px;
    }
}

.hp_avis--hero
{
    position: relative;
    z-index: 9;

    overflow: hidden;

    height: 440px;

    text-align: center;

    background: url('../../images/portal/bg-video-hero.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
@media (max-width: 767px)
{
    .hp_avis--hero
    {
        height: 390px;
    }
}
.hp_avis--hero:before
{
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    content: '';

    background: rgba(0, 130, 195, .4);
}
.hp_avis--hero .hp_avis--container
{
    position: absolute;
    z-index: 9;
    top: 0;
    left: 50%;

    width: 100%;
    padding: 60px 20px 0 20px;

    -webkit-transform: translateX(-50%);

            transform: translateX(-50%);
}
@media (max-width: 980px)
{
    .hp_avis--hero .hp_avis--container
    {
        right: 10px;
        left: 10px;

        -webkit-transform: translateX(0);

                transform: translateX(0);
    }
}
@media (max-width: 767px)
{
    .hp_avis--hero .hp_avis--container
    {
        padding-top: 30px;
    }
}
.hp_avis--hero .hp_avis--hero-logo
{
    width: 140px;
    margin: 0 auto;
    margin-bottom: 30px;
}
@media (max-width: 767px)
{
    .hp_avis--hero .hp_avis--hero-logo
    {
        width: 110px;
        margin-bottom: 20px;
    }
}
.hp_avis--hero .hp_avis--hero-text
{
    font-size: 18px;
    line-height: 140%;

    max-width: 880px;
    margin: 0 auto 40px auto;

    color: #fff;
}
@media (max-width: 1400px)
{
    .hp_avis--hero .hp_avis--hero-text
    {
        max-width: none;
    }
}
@media (max-width: 767px)
{
    .hp_avis--hero .hp_avis--hero-text
    {
        font-size: 14px;

        margin-bottom: 20px;
    }
}
.hp_avis--hero .btn
{
    display: block;

    max-width: 230px;
    margin: 0 auto;
    margin-bottom: 40px;
}
.hp_avis--hero .link
{
    font-size: 14px;
    font-weight: 600;

    text-decoration: underline;

    color: #fff;
}
.hp_avis--hero .link:hover
{
    text-decoration: none;
}

.hp_avis--hero-video
{
    position: relative;
    z-index: 1;

    display: block;

    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
}
@media (max-width: 1400px)
{
    .hp_avis--hero-video
    {
        position: relative;
        left: 50%;

        height: 640px;

        -webkit-transform: translateX(-50%);

                transform: translateX(-50%);
    }
}
@media (max-width: 767px)
{
    .hp_avis--hero-video
    {
        display: none;

        height: 420px;
    }
}

.hp_avis--hero-video-iframe
{
    position: relative;

    overflow: hidden;

    height: 0;
    padding-top: 0;
    padding-bottom: 56.25%;
}
@media (max-width: 1400px)
{
    .hp_avis--hero-video-iframe
    {
        height: 630px;
    }
}
@media (max-width: 767px)
{
    .hp_avis--hero-video-iframe
    {
        display: none;

        height: 470px;
    }
}
.hp_avis--hero-video-iframe iframe,
.hp_avis--hero-video-iframe object,
.hp_avis--hero-video-iframe embed
{
    position: absolute;
    top: 0;
    left: 0;
    left: 50%;

    width: 200%;
    height: 100%;

    -webkit-transform: translateX(-50%);

            transform: translateX(-50%);
}
@media (max-width: 767px)
{
    .hp_avis--hero-video-iframe iframe,
    .hp_avis--hero-video-iframe object,
    .hp_avis--hero-video-iframe embed
    {
        width: 300%;
    }
}

.hp_avis--section
{
    padding: 50px 20px;
}
@media (max-width: 767px)
{
    .hp_avis--section
    {
        padding: 40px 10px;
    }
}
.hp_avis--section.skewed-bg
{
    position: relative;

    padding-top: 80px;

    -webkit-transform: skew(0deg, -5deg);

            transform: skew(0deg, -5deg);
}
@media (max-width: 767px)
{
    .hp_avis--section.skewed-bg
    {
        padding-top: 40px;
    }
}
.hp_avis--section.skewed-bg .hp_avis--container
{
    -webkit-transform: skew(0deg, 5deg);
            transform: skew(0deg, 5deg);
}
.hp_avis--section.hp_avis--section-stories
{
    padding-top: 70px;

    background: #f4f3f3;
}
@media (max-width: 767px)
{
    .hp_avis--section.hp_avis--section-stories
    {
        padding-top: 40px;
    }
}
.hp_avis--section.hp_avis--section-stories .txtcenter
{
    padding-top: 50px;
    padding-bottom: 50px;
}
@media (max-width: 767px)
{
    .hp_avis--section.hp_avis--section-stories .txtcenter
    {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
.hp_avis--section.hp_avis--section-stories .txtcenter p
{
    font-size: 20px;
    line-height: 120%;

    max-width: 570px;
    margin-right: auto;
    margin-bottom: 60px;
    margin-left: auto;
}
@media (max-width: 767px)
{
    .hp_avis--section.hp_avis--section-stories .txtcenter p
    {
        font-size: 17px;

        margin-bottom: 45px;
    }
}
.hp_avis--section.hp_avis--section-how-it-works
{
    background: #fff;
}
.hp_avis--section.hp_avis--section-img-band
{
    position: relative;
}

.hp_avis--list-howto
{
    margin-bottom: 60px;
}
@media (max-width: 767px)
{
    .hp_avis--list-howto
    {
        margin-bottom: 0;
    }
}
.hp_avis--list-howto li
{
    position: relative;

    float: left;

    width: 33.333%;
    min-height: 280px;
    /*&:after {
			content: "";
			display: block;
			background: url("../img/arrow-right-black.svg") no-repeat center center;
			background-size: 20px auto;
			width: 40px;
			height: 40px;
			position: absolute;
			top: 60%;
			right: -20px;
			opacity: 0.2;
			@include breakpoint(860px) {
				top: 55%;
				background-size: 15px auto;
			}
			@include breakpoint($small) {
				display: none;
			}
		}*/
}
@media (max-width: 767px)
{
    .hp_avis--list-howto li
    {
        float: none;

        width: auto;
        margin-bottom: 0;
    }
}
.hp_avis--list-howto li:last-child:after
{
    display: none;
}

.hp_avis--section-how-it-works
{
    padding-bottom: 80px;
}
@media (max-width: 767px)
{
    .hp_avis--section-how-it-works
    {
        padding-bottom: 40px;
    }
}
.hp_avis--section-how-it-works .txtcenter p
{
    font-size: 20px;

    margin-bottom: 40px;

    color: #393939;
}
.hp_avis--section-how-it-works .block-card-howto
{
    position: relative;

    cursor: pointer;
    transition: 200ms;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    text-align: center;
}
@media (max-width: 1400px)
{
    .hp_avis--section-how-it-works .block-card-howto
    {
        padding: 0 20px;
    }
}
.hp_avis--section-how-it-works .block-card-howto .hp_avis--list-howto-img img
{
    width: 100%;
    max-width: 250px;
    margin: 0 auto;

    transition: 200ms;
}
.hp_avis--section-how-it-works .block-card-howto .hp_avis--list-howto-title
{
    font-size: 22px;
    font-weight: 700;

    max-width: 300px;
    margin: 0 auto 10px auto;

    color: #393939;
}
@media (max-width: 767px)
{
    .hp_avis--section-how-it-works .block-card-howto .hp_avis--list-howto-title
    {
        font-size: 18px;
    }
}
.hp_avis--section-how-it-works .block-card-howto .hp_avis--list-howto-text
{
    font-size: 14px;
    font-weight: 700;

    position: absolute;
    left: 50%;

    visibility: hidden;

    max-width: 270px;
    min-height: 100px;
    margin: 0 auto;

    transition: 200ms;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

    opacity: 0;
    color: #393939;
}
@media (max-width: 767px)
{
    .hp_avis--section-how-it-works .block-card-howto .hp_avis--list-howto-text
    {
        display: none;
        visibility: visible;

        opacity: 1;
    }
}
.hp_avis--section-how-it-works .block-card-howto .hp_avis--expand-button,
.hp_avis--section-how-it-works .block-card-howto .hp_avis--close-button
{
    font-size: 14px;
    font-weight: 600;

    display: inline-block;

    cursor: pointer;
    text-decoration: none;

    color: #b3b3b3;
    border: none;
    background: none;
}
.hp_avis--section-how-it-works .block-card-howto .hp_avis--close-button
{
    margin-top: 10px;
}
.hp_avis--section-how-it-works .block-card-howto.active
{
    -webkit-transform: translateY(-30%);
            transform: translateY(-30%);
}
.hp_avis--section-how-it-works .block-card-howto.active .hp_avis--list-howto-img
{
    visibility: hidden;

    opacity: 0;
}
.hp_avis--section-how-it-works .block-card-howto.active .hp_avis--expand-button
{
    display: none;
}
.hp_avis--section-how-it-works .block-card-howto.active .hp_avis--list-howto-text
{
    visibility: visible;

    opacity: 1;
}
@media (max-width: 767px)
{
    .hp_avis--section-how-it-works .block-card-howto.active .hp_avis--list-howto-text
    {
        display: block;
    }
}

.hp_avis--section-img-band
{
    padding: 45px 0;

    text-align: center;

    background-position: center center;
    background-size: fixed;
}
.hp_avis--section-img-band img
{
    display: inline-block;

    width: 150px;
}

.swiper-button
{
    position: absolute;
    z-index: 99;
    top: 50%;

    width: 50px;
    height: 50px;
    margin-top: -25px;

    transition: all 300ms;

    border: none;
    background-color: #fff;
}
.swiper-button.swiper-button-prev
{
    left: 0;

    background: url('../../images/portal/arrow-left-blue.svg') no-repeat center center;
    background-size: 15px auto;
}
.swiper-button.swiper-button-next
{
    right: 0;

    background: url('../../images/portal/arrow-right-blue.svg') no-repeat center center;
    background-size: 15px auto;
}
.swiper-button.swiper-button-disabled
{
    visibility: hidden;

    opacity: 0;
}

.swiper-pagination
{
    text-align: center;
}
.swiper-pagination .swiper-pagination-bullet
{
    width: 12px;
    height: 12px;
    margin: 0 6px;

    transition: 300ms;
    text-indent: -9999px;

    opacity: 1;
    border-radius: 12px;
    background: #bfbfbf;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active
{
    background: #0082c3;
}

.hp_avis--carousel
{
    overflow: visible;

    max-width: 1080px;
    margin: 0 auto;
    margin-bottom: 40px;
}
.hp_avis--carousel .swiper-slide
{
    opacity: .6;
}
.hp_avis--carousel .swiper-slide.swiper-slide-active
{
    opacity: 1;
}
.hp_avis--carousel .swiper-pagination
{
    bottom: -30px;
}
.hp_avis--carousel .swiper-button
{
    display: flex;

    margin-top: -25px;

    cursor: pointer;
    transition: all 300ms;

    border: none;
    border-radius: 50px;
    background-color: #fff;
    box-shadow: 5px -5px 10px 0 rgba(0, 0, 0, .2);

    align-items: center;
    justify-content: center;
}
.hp_avis--carousel .swiper-button.swiper-button-prev
{
    left: -60px;

    background: url('../../images/portal/arrow-left-black.svg') no-repeat center center;
    background-color: #fff;
    box-shadow: -5px -5px 10px 0 rgba(0, 0, 0, .2);
}
.hp_avis--carousel .swiper-button.swiper-button-prev:hover
{
    box-shadow: -5px -5px 20px 0 rgba(0, 0, 0, .2);
}
.hp_avis--carousel .swiper-button.swiper-button-next
{
    right: -60px;

    background: url('../../images/portal/arrow-right-black.svg') no-repeat center center;
    background-color: #fff;
    box-shadow: 5px -5px 10px 0 rgba(0, 0, 0, .2);
}
.hp_avis--carousel .swiper-button.swiper-button-next:hover
{
    box-shadow: 5px -5px 20px 0 rgba(0, 0, 0, .2);
}
.hp_avis--carousel .hp_avis--carousel-item
{
    position: relative;

    max-width: 1080px;
    height: 430px;
    margin: 0 40px;
    margin: 0 auto;
    margin-bottom: 60px;
    padding: 50px 50px 50px 40px;

    background: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
}
@media (max-width: 767px)
{
    .hp_avis--carousel .hp_avis--carousel-item
    {
        height: auto;
        padding: 10px;
    }
}
.hp_avis--carousel .hp_avis--carousel-item:after
{
    position: absolute;
    bottom: -50px;
    left: 50%;

    display: block;

    width: 0;
    height: 0;
    margin-left: -20px;

    content: '';

    border-width: 50px 85px 0 0;
    border-style: solid;
    border-color: #fff transparent transparent transparent;

    -webkit-filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, .1));

            filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, .1));
}
.hp_avis--carousel .hp_avis--carousel-item--img
{
    position: absolute;
    z-index: -1;
    top: -160px;
    left: 0;

    pointer-events: none;
}
@media (max-width: 980px)
{
    .hp_avis--carousel .hp_avis--carousel-item--img
    {
        top: -90px;
        left: -30px;

        width: 50%;
    }
}
@media (max-width: 767px)
{
    .hp_avis--carousel .hp_avis--carousel-item--img
    {
        top: -70px;
        left: -50px;
    }
}
.hp_avis--carousel .hp_avis--carousel-item--video
{
    display: flex;
    float: left;

    width: 63%;
    height: 100%;

    align-items: center;
}
.hp_avis--carousel .hp_avis--carousel-item--video .hp_avis--carousel-item--video-content
{
    width: 100%;
    /*position: relative;
	    padding-bottom: 56.25%;
	    padding-top: 0;
	    height: 0;
	    overflow: hidden;*/
}
.hp_avis--carousel .hp_avis--carousel-item--video .hp_avis--carousel-item--video-content iframe,
.hp_avis--carousel .hp_avis--carousel-item--video .hp_avis--carousel-item--video-content object,
.hp_avis--carousel .hp_avis--carousel-item--video .hp_avis--carousel-item--video-content embed
{
    /*position: absolute;
		    top: 0;
		    left: 0;
		    height: 100%;*/
    width: 100%;
}
@media (max-width: 767px)
{
    .hp_avis--carousel .hp_avis--carousel-item--video
    {
        display: block;
    }
}
.hp_avis--carousel .hp_avis--carousel-item--video a
{
    display: block;
}
@media (max-width: 767px)
{
    .hp_avis--carousel .hp_avis--carousel-item--video a img
    {
        width: 100%;
    }
}
@media (max-width: 767px)
{
    .hp_avis--carousel .hp_avis--carousel-item--video
    {
        float: none;

        width: auto;
    }
}
.hp_avis--carousel .hp_avis--carousel-item--quote
{
    display: block;
    display: flex;
    float: right;

    width: 37%;
    height: 100%;

    align-items: center;
}
@media (max-width: 767px)
{
    .hp_avis--carousel .hp_avis--carousel-item--quote
    {
        float: none;

        width: auto;
        padding: 10px 0 20px 0;
    }
}
.hp_avis--carousel .hp_avis--carousel-item--quote blockquote
{
    font-family: 'Roboto Condensed', Helvetica, 'Helvetica Neue', Arial, sans-serif;

    display: block;

    max-width: 100%;

    flex-shrink: 1;
}
.hp_avis--carousel .hp_avis--carousel-item--quote p
{
    font-size: 24px;
    font-weight: 300;
    font-style: italic;
    line-height: 120%;

    margin-bottom: 15px;
    padding: 30px 0 30px 80px;

    background: url('../../images/portal/quote.svg') no-repeat 30px top;
}
@media (max-width: 767px)
{
    .hp_avis--carousel .hp_avis--carousel-item--quote p
    {
        font-size: 20px;

        padding: 25px 0 0 25px;

        background-position: 0 0;
        background-size: 30px auto;
    }
}
.hp_avis--carousel .hp_avis--carousel-item--quote span
{
    font-size: 13px;

    display: block;

    padding-left: 80px;
}
@media (max-width: 767px)
{
    .hp_avis--carousel .hp_avis--carousel-item--quote span
    {
        padding-left: 25px;
    }
}
.hp_avis--carousel .hp_avis--carousel-item--quote span u
{
    font-size: 15px;
    font-weight: 700;

    display: block;

    text-decoration: none;

    color: #0082c3;
}
.hp_avis--carousel .hp_avis--carousel-item--text
{
    text-align: center;
}
@media (max-width: 767px)
{
    .hp_avis--carousel .hp_avis--carousel-item--text
    {
        margin-bottom: 60px;
    }
}
.hp_avis--carousel .hp_avis--carousel-item--text img
{
    display: inline-block;
}
.hp_avis--carousel .hp_avis--carousel-item--text p
{
    font-size: 24px;
    font-weight: 300;
    font-style: italic;
    line-height: 140%;
}
@media (max-width: 767px)
{
    .hp_avis--carousel .hp_avis--carousel-item--text p
    {
        font-size: 20px;
    }
}

/*-----------------------------------------------------------------------------------------------------
    [homepage]
-------------------------------------------------------------------------------------------------------*/
.home-review > .container
{
    padding: 0;
}
@media (max-width: 767px)
{
    .home-review
    {
        /*background: url('../img/bg-home-review-mobile.png') #FFF  no-repeat center -2%;
		background-size: 100% auto;*/
        background: url('../../images/portal/bg-home-review-mobile.jpg') #fff no-repeat left -10px;
        background-size: 100% auto;
    }
}
.home-review .home-review--hero
{
    position: relative;

    padding: 50px 20px 0 20px;

    text-align: center;
}
.home-review .home-review--hero:after
{
    position: absolute;
    z-index: 1;
    top: 100px;
    left: 0;

    display: none;

    width: 100%;
    height: 200px;

    content: '';
    -webkit-transform: skew(0deg, -5deg);
            transform: skew(0deg, -5deg);

    background: #fff;
}
@media (max-width: 767px)
{
    .home-review .home-review--hero
    {
        overflow: hidden;

        padding-top: 20px;
    }
    .home-review .home-review--hero:after
    {
        display: block;
    }
}
.home-review .home-review--hero .home-review--hero-title
{
    font-size: 60px;

    position: relative;
    z-index: 2;

    display: inline-block;

    margin-bottom: 30px;
    padding: 20px;

    text-transform: uppercase;

    color: #fff;
    border: 3px solid #fff;
}
@media (max-width: 767px)
{
    .home-review .home-review--hero .home-review--hero-title
    {
        font-size: 25px;

        margin-bottom: 60px;
        padding: 10px 10px;

        border: 1.5px solid #fff;
    }
}
.home-review .home-review--hero .home-review--hero-text
{
    font-size: 22px;
    font-weight: bold;
    line-height: 120%;

    position: relative;
    z-index: 2;

    margin-bottom: 10px;

    color: #fff;
}
@media (max-width: 767px)
{
    .home-review .home-review--hero .home-review--hero-text
    {
        font-size: 14px;
        font-weight: normal;

        color: #242323;
    }
}
.home-review .home-review--hero .home-review--hero-link
{
    font-size: 18px;

    position: relative;
    z-index: 2;

    display: inline-block;

    margin-bottom: 30px;

    text-decoration: underline;

    color: #fff;
}
@media (max-width: 767px)
{
    .home-review .home-review--hero .home-review--hero-link
    {
        font-size: 14px;

        color: #0082c3;
    }
}
.home-review .home-review--hero .home-review--hero-icon
{
    position: absolute;
    top: 25px;
    left: -50px;
}
@media (max-width: 980px)
{
    .home-review .home-review--hero .home-review--hero-icon
    {
        display: none;
    }
}
.home-review .home-review--actions .block-home-review
{
    float: left;

    width: 30%;
    min-height: 250px;
    margin: 0 1.5%;
    padding: 20px;

    text-align: center;

    background: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
}
@media (max-width: 850px)
{
    .home-review .home-review--actions .block-home-review
    {
        min-height: 290px;
    }
}
@media (max-width: 767px)
{
    .home-review .home-review--actions .block-home-review
    {
        float: none;

        width: auto;
        min-height: 0;
        margin: 0;

        border-top: 1px solid #e6e6e6;
    }
}
.home-review .home-review--actions .block-home-review .block-home-review--icon
{
    position: relative;

    display: inline-block;

    width: 140px;
    margin-bottom: 15px;
}
.home-review .home-review--actions .block-home-review .block-home-review--icon span
{
    position: relative;
    z-index: 2;

    display: inline-block;

    width: 80px;

    background: #fff;
}
.home-review .home-review--actions .block-home-review .block-home-review--icon span img
{
    display: inline-block;

    max-width: 50px;
}
.home-review .home-review--actions .block-home-review .block-home-review--icon:after
{
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;

    display: block;

    width: 100%;
    height: 2px;

    content: '';
    -webkit-transform: rotate(-9deg);
            transform: rotate(-9deg);

    background: #0082c3;
}
.home-review .home-review--actions .block-home-review .block-home-review--title
{
    font-size: 26px;
    font-weight: bold;
    font-style: italic;

    margin-bottom: 20px;

    text-transform: uppercase;

    color: #242323;
}
.home-review .home-review--actions .block-home-review .block-home-review--text
{
    min-height: 60px;
    margin-bottom: 10px;

    text-align: left;
}
@media (max-width: 767px)
{
    .home-review .home-review--actions .block-home-review .block-home-review--text
    {
        min-height: 0;
        margin-bottom: 20px;
    }
}
.home-review .home-review--actions .block-home-review .st-btn
{
    display: block;

    margin: 0;
    padding-right: 41px;
    padding-left: 15px;
}
@media (max-width: 980px)
{
    .home-review .home-review--actions .block-home-review .st-btn
    {
        font-size: 14px;
    }
}
@media (max-width: 767px)
{
    .home-review .home-review--actions .block-home-review .st-btn
    {
        font-size: 16px;
    }
}
